import React from 'react';
import './FooterStyle.css';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        
            <div className="footer">
                <div className="footer-logo">
                <p>Follow us on Instagram</p>
                <a href="https://www.instagram.com/_barista.cafe">
                 <FaInstagram className="footer-icon" />
                </a>
                </div>
                <p>&copy; 2023 Barista. All rights reserved.</p>
            </div>
        
    );
};

export default Footer;