import React from 'react';
import './HeaderStyle.css'; 
import logo from '../../images/black-logo.png'
const Header = () => {
    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="Barista Logo" />
            </div>
            
        </header>
    );
};

export default Header;