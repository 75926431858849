import React from 'react';
import './MenuPageStyle.css';
import { MdOutlineArrowBack } from "react-icons/md";
import { Link } from 'react-router-dom';



const MenuPage = ({name}) => {
    const menuItems = [
        { name: 'Espresso', price: '$2.00' },
        { name: 'Cappuccino', price: '$3.50' },
        { name: 'Latte', price: '$4.00' },
        { name: 'Mocha', price: '$4.50' },
        { name: 'Americano', price: '$2.50' },
    ];

    return (
        <div>
            <Link to="/" className="back-button"><MdOutlineArrowBack style={{ fontSize: '2rem' }} /></Link>
            <h1>{name}</h1>
            <ul>
                {menuItems.map((item, index) => (
                    <li key={index}>
                        {item.name} - {item.price}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MenuPage;