import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link} from 'react-router-dom';
import hotDrink from '../../images/hot-coffee.jpg';
import coldDrink from '../../images/cold-drinks.jpg';
import cookies from '../../images/cookies.jpg';
import coffeeBeans from '../../images/coffee-beans.jpg';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './SwiperStyle.css';

import { EffectCoverflow, Pagination } from 'swiper/modules';

export default function MySwiper() {
    return (
        <>
            <Swiper
                effect={ 'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={3}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide > 
                    <div className="slide-content">
                    <Link to='/hot-drinks' >
                        <img alt='Category' src={hotDrink} /> 
                    </Link>
                        <p className="slide-text">משקה חם</p>
                       
                    </div>
                </SwiperSlide>     
                <SwiperSlide>
                    <div className="slide-content">
                        <Link to='/cold-drinks'>
                        <img alt='Category' src={coldDrink} />
                        </Link>
                        <p className="slide-text">משקה קר</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-content">
                        <Link to='/cookies'>
                        <img alt='Category' src={cookies}/>
                        </Link>
                        <p className="slide-text">קוקיז</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-content">
                        <Link to='/coffee-beans'>
                        <img alt='Category' src={coffeeBeans} />
                        </Link>
                        <p className="slide-text">פולי קפה</p>
                    </div>
                </SwiperSlide>
                
            </Swiper>
        </>
    );
}
