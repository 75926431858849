import React from 'react';
import './HomePageStyle.css'
import MySwiper from '../../components/Swiper/Swiper';

const HomePage = () => {
    return (
        <div className='home-page-container'>
            <MySwiper/> 
        </div>
    );
};

export default HomePage;