import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import HomePage from './pages/HomePage/HomePage';
import MenuPage from './pages/MenuPage/MenuPage';
import Footer from './components/Footer/Footer'; 
import './App.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="app-container" data-aos="fade-zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Header />
        <div className="content">
          <Routes>
            <Route path='/' element={<HomePage />} />
             <Route path='/hot-drinks' element={<MenuPage name={"משקה חם"}/>} />
            <Route path='/cold-drinks' element={<MenuPage name={"משקה קר"} />} />
            <Route path='/cookies' element={<MenuPage name={"קוקיז"} />} />
            <Route path='/coffee-beans' element={<MenuPage name={"פולי קפה"} />} /> 
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;